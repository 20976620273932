<template>
  <div class="bg-gray-100 w-[100vw] h-[100vh] flex items-center justify-center">
    <div class="w-[704px] mr-20" style="margin-right: 10rem;">
      <div class="mb-8 bg-black shadow-lg w-full h-[250px] relative border-black border">
        <div :style="{ width: pickL1 ? 100 + '%' : '20' + '%', 'z-index': pickL1 ? 99 : 0, left: 0 }"
          class="card absolute transition-all duration-[0.6s] overflow-hidden">
          <div v-if="lockL1" class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center">
            <img src="../assets/locked.png" class="w-12 h-12">
          </div>
          <img v-if="preHeroL1" :src="preHeroL1" class="relative w-full object-cover h-[250px]">
          <!-- style="object-position: 100% 20%;" -->
          <img class="relative w-full object-cover h-[250px]" :src="imageSourceL1">
          <!-- style="object-position: 100% 10%;"  -->
          <div v-if="preHeroL1" class="absolute inset-0 animate-pulse opacity-80 "></div>
          <div class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2">
            {{ heroL1 ? heroL1.replace(/\..+$/, '') : 'Hero 1' }}
          </div>
        </div>
        <div
          :style="{ width: pickL2 ? 50 + '%' : '20' + '%', 'z-index': pickL2 ? 99 : 0, left: pickL2 ? '0' : '20' + '%' }"
          class="card absolute transition-all duration-[0.6s] overflow-hidden">
          <div v-if="lockL2" class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center">
            <img src="../assets/locked.png" class="w-12 h-12">
          </div>
          <img v-if="preHeroL2" :src="preHeroL2" class="relative w-full object-cover h-[250px]">
          <!-- style="object-position: 100% 20%;" -->
          <img class="relative w-full object-cover h-[250px]" :src="imageSourceL2">
          <!-- style="object-position: 100% 20%;" -->
          <div v-if="preHeroL2" class="absolute inset-0 animate-pulse opacity-80 "></div>
          <div class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2">
            {{ heroL2 ? heroL2.replace(/\..+$/, '') : 'Hero 2' }}
          </div>
        </div>
        <div
          :style="{ width: pickL2 ? 50 + '%' : '20' + '%', 'z-index': pickL2 ? 99 : 0, left: pickL2 ? '50' + '%' : '40' + '%' }"
          class="card absolute transition-all duration-[0.6s] overflow-hidden">
          <div v-if="lockL3" class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center">
            <img src="../assets/locked.png" class="w-12 h-12">
          </div>
          <img v-if="preHeroL3" :src="preHeroL3" class="relative w-full object-cover h-[250px]">
          <!-- style="object-position: 100% 20%;" -->
          <img class="relative w-full object-cover h-[250px]" :src="imageSourceL3">
          <!-- style="object-position: 100% 20%;" -->
          <div v-if="preHeroL3" class="absolute inset-0 animate-pulse opacity-80 "></div>
          <div class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2">
            {{ heroL3 ? heroL3.replace(/\..+$/, '') : 'Hero 3' }}
          </div>
        </div>
        <div
          :style="{ width: pickL3 ? 50 + '%' : '20' + '%', 'z-index': pickL3 ? 99 : 0, left: pickL3 ? '0' : '60' + '%' }"
          class="card absolute transition-all duration-[0.6s] overflow-hidden">
          <div v-if="lockL4" class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center">
            <img src="../assets/locked.png" class="w-12 h-12">
          </div>
          <img v-if="preHeroL4" :src="preHeroL4" class="relative w-full object-cover h-[250px]">
          <img class="relative w-full object-cover h-[250px]" :src="imageSourceL4">
          <div v-if="preHeroL4"
            class="absolute inset-0 animate-pulse opacity-80 bg-gradient-to-t from-yellow-600 to-transparent"></div>
          <div class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2">
            {{ heroL4 ? heroL4.replace(/\..+$/, '') : 'Hero 4' }}
          </div>
        </div>
        <div
          :style="{ width: pickL3 ? 50 + '%' : '20' + '%', 'z-index': pickL3 ? 99 : 0, left: pickL3 ? '50' + '%' : '80' + '%' }"
          class="card absolute transition-all duration-[0.6s] overflow-hidden">
          <div v-if="lockL5" class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center">
            <img src="../assets/locked.png" class="w-12 h-12">
          </div>
          <img v-if="preHeroL5" :src="preHeroL5" class="relative w-full object-cover h-[250px]">
          <img class="relative w-full object-cover h-[250px]" :src="imageSourceL5">
          <div v-if="preHeroL5"
            class="absolute inset-0 animate-pulse opacity-80 bg-gradient-to-t from-yellow-600 to-transparent"></div>
          <div class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2">
            {{ heroL5 ? heroL5.replace(/\..+$/, '') : 'Hero 5' }}
          </div>
        </div>
      </div>

      <div class="grid grid-cols-5">
        <div class="p-2">
          <div :class="[pickL1 ? 'bg-green-500' : 'bg-blue-500']"
            class="bg-blue-500 text-white rounded text-center text-sm hover:bg-blue-600 cursor-pointer"
            @click="expand('pickL1')">{{ pickL1 ? 'Close' : 'Expand' }}</div>
        </div>
        <div class="p-2 col-span-2">
          <div :class="[pickL2 ? 'bg-green-500' : 'bg-blue-500']"
            class="bg-blue-500 text-white rounded text-center text-sm hover:bg-blue-600 cursor-pointer"
            @click="expand('pickL2')">{{ pickL2 ? 'Close' : 'Expand' }}</div>
        </div>
        <div class="p-2 col-span-2">
          <div :class="[pickL3 ? 'bg-green-500' : 'bg-blue-500']"
            class="bg-blue-500 text-white rounded text-center text-sm hover:bg-blue-600 cursor-pointer"
            @click="expand('pickL3')">{{ pickL3 ? 'Close' : 'Expand' }}</div>
        </div>
      </div>
    </div>

    <div class="w-[704px] ml-20" style="margin-left: 10rem;">
      <div class="mb-8 bg-black shadow-lg w-full h-[250px] relative border-black border">
        <div :style="{ width: pickR1 ? 100 + '%' : '20' + '%', 'z-index': pickR1 ? 99 : 0, left: 0 }"
          class="card absolute transition-all duration-[0.6s] overflow-hidden">
          <div v-if="lockR1" class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center">
            <img src="../assets/locked.png" class="w-12 h-12">
          </div>
          <img v-if="preHeroR1" :src="preHeroR1" class="relative w-full object-cover h-[250px]">
          <!-- style="object-position: 100% 20%;" -->
          <img class="relative w-full object-cover h-[250px]" :src="imageSourceR1">
          <!-- style="object-position: 100% 10%;"  -->
          <div v-if="preHeroR1" class="absolute inset-0 animate-pulse opacity-80 "></div>
          <div class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2">
            {{ heroR1 ? heroR1.replace(/\..+$/, '') : 'Hero 1' }}
          </div>
        </div>
        <div
          :style="{ width: pickR2 ? 50 + '%' : '20' + '%', 'z-index': pickR2 ? 99 : 0, left: pickR2 ? '0' : '20' + '%' }"
          class="card absolute transition-all duration-[0.6s] overflow-hidden">
          <div v-if="lockR2" class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center">
            <img src="../assets/locked.png" class="w-12 h-12">
          </div>
          <img v-if="preHeroR2" :src="preHeroR2" class="relative w-full object-cover h-[250px]">
          <!-- style="object-position: 100% 20%;" -->
          <img class="relative w-full object-cover h-[250px]" :src="imageSourceR2">
          <!-- style="object-position: 100% 20%;" -->
          <div v-if="preHeroR2" class="absolute inset-0 animate-pulse opacity-80 "></div>
          <div class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2">
            {{ heroR2 ? heroR2.replace(/\..+$/, '') : 'Hero 2' }}
          </div>
        </div>
        <div
          :style="{ width: pickR2 ? 50 + '%' : '20' + '%', 'z-index': pickR2 ? 99 : 0, left: pickR2 ? '50' + '%' : '40' + '%' }"
          class="card absolute transition-all duration-[0.6s] overflow-hidden">
          <div v-if="lockR3" class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center">
            <img src="../assets/locked.png" class="w-12 h-12">
          </div>
          <img v-if="preHeroR3" :src="preHeroR3" class="relative w-full object-cover h-[250px]">
          <!-- style="object-position: 100% 20%;" -->
          <img class="relative w-full object-cover h-[250px]" :src="imageSourceR3">
          <!-- style="object-position: 100% 20%;" -->
          <div v-if="preHeroR3" class="absolute inset-0 animate-pulse opacity-80 "></div>
          <div class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2">
            {{ heroR3 ? heroR3.replace(/\..+$/, '') : 'Hero 3' }}
          </div>
        </div>
        <div
          :style="{ width: pickR3 ? 50 + '%' : '20' + '%', 'z-index': pickR3 ? 99 : 0, left: pickR3 ? '0' : '60' + '%' }"
          class="card absolute transition-all duration-[0.6s] overflow-hidden">
          <div v-if="lockR4" class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center">
            <img src="../assets/locked.png" class="w-12 h-12">
          </div>
          <img v-if="preHeroR4" :src="preHeroR4" class="relative w-full object-cover h-[250px]">
          <img class="relative w-full object-cover h-[250px]" :src="imageSourceR4">
          <div v-if="preHeroR4"
            class="absolute inset-0 animate-pulse opacity-80 bg-gradient-to-t from-yellow-600 to-transparent"></div>
          <div class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2">
            {{ heroR4 ? heroR4.replace(/\..+$/, '') : 'Hero 4' }}
          </div>
        </div>
        <div
          :style="{ width: pickR3 ? 50 + '%' : '20' + '%', 'z-index': pickR3 ? 99 : 0, left: pickR3 ? '50' + '%' : '80' + '%' }"
          class="card absolute transition-all duration-[0.6s] overflow-hidden">
          <div v-if="lockR5" class="absolute z-50 inset-x-0 bottom-0 h-[45%] flex items-start justify-center">
            <img src="../assets/locked.png" class="w-12 h-12">
          </div>
          <img v-if="preHeroR5" :src="preHeroR5" class="relative w-full object-cover h-[250px]">
          <img class="relative w-full object-cover h-[250px]" :src="imageSourceR5">
          <div v-if="preHeroR5"
            class="absolute inset-0 animate-pulse opacity-80 bg-gradient-to-t from-yellow-600 to-transparent"></div>
          <div class="absolute inset-x-0 bottom-0 bg-yellow-400 text-center text-sm p-2">
            {{ heroR5 ? heroR5.replace(/\..+$/, '') : 'Hero 5' }}
          </div>
        </div>
      </div>
      <div class="grid grid-cols-5">
        <div class="p-2">
          <div :class="[pickR1 ? 'bg-green-500' : 'bg-blue-500']"
            class="bg-blue-500 text-white rounded text-center text-sm hover:bg-blue-600 cursor-pointer"
            @click="expand('pickR1')">{{ pickR1 ? 'Close' : 'Expand' }}</div>
        </div>
        <div class="p-2 col-span-2">
          <div :class="[pickR2 ? 'bg-green-500' : 'bg-blue-500']"
            class="bg-blue-500 text-white rounded text-center text-sm hover:bg-blue-600 cursor-pointer"
            @click="expand('pickR2')">{{ pickR2 ? 'Close' : 'Expand' }}</div>
        </div>
        <div class="p-2 col-span-2">
          <div :class="[pickR3 ? 'bg-green-500' : 'bg-blue-500']"
            class="bg-blue-500 text-white rounded text-center text-sm hover:bg-blue-600 cursor-pointer"
            @click="expand('pickR3')">{{ pickR3 ? 'Close' : 'Expand' }}</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "BanPickView",
  components: {
  },

  data() {
    return {

      imageSourceL1: "/bg.jpg",
      imageSourceL2: "/bg.jpg",
      imageSourceL3: "/bg.jpg",
      imageSourceL4: "/bg.jpg",
      imageSourceL5: "/bg.jpg",
      imageSourceR1: "/bg.jpg",
      imageSourceR2: "/bg.jpg",
      imageSourceR3: "/bg.jpg",
      imageSourceR4: "/bg.jpg",
      imageSourceR5: "/bg.jpg",
      pickL1: false,
      pickL2: false,
      pickL3: false,
      pickR1: false,
      pickR2: false,
      pickR3: false,

      lockL1: false,
      lockL2: false,
      lockL3: false,
      lockL4: false,
      lockL5: false,
      lockR1: false,
      lockR2: false,
      lockR3: false,
      lockR4: false,
      lockR5: false,

      heroL1: 'Hero 1',
      heroL2: 'Hero 2',
      heroL3: 'Hero 3',
      heroL4: 'Hero 4',
      heroL5: 'Hero 5',
      heroR1: 'Hero 1',
      heroR2: 'Hero 2',
      heroR3: 'Hero 3',
      heroR4: 'Hero 4',
      heroR5: 'Hero 5',

      preHeroL1: null,
      preHeroL2: null,
      preHeroL3: null,
      preHeroL4: null,
      preHeroL5: null,
      preHeroR1: null,
      preHeroR2: null,
      preHeroR3: null,
      preHeroR4: null,
      preHeroR5: null,
    };
  },
  methods: {
    expand(val) {
      if (['pickL1', 'pickL2', 'pickL3', 'pickR1', 'pickR2', 'pickR3'].includes(val)) {
        this[val] = true;
        setTimeout(() => {
          this[val] = false;
        }, 2000);
      }
    }
  }
}

</script>

<style scoped>
.card {
  height: 100%;
  width: 100%;
}
</style>
