import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
const config = {

//server

  apiKey: "AIzaSyDgFzZ08XAoV9JaPHQrvuEepNLY-ofEEIc",
  authDomain: "overlay-prod-us.firebaseapp.com",
  databaseURL: "https://overlay-prod-us-default-rtdb.firebaseio.com",
  projectId: "overlay-prod-us",
  storageBucket: "overlay-prod-us.appspot.com",
  messagingSenderId: "656185908602",
  appId: "1:656185908602:web:75712b4688a318de9dcac3"

//local

  // apiKey: "AIzaSyBtG-PZmOXNmDj6l2sa0j-0vXuyt9xDJBc",
  // authDomain: "test-new-region.firebaseapp.com",
  // databaseURL: "https://test-new-region-default-rtdb.firebaseio.com",
  // projectId: "test-new-region",
  // storageBucket: "test-new-region.firebasestorage.app",
  // messagingSenderId: "648411978998",
  // appId: "1:648411978998:web:31c6ed9f9c05f8834f60d7",
  // measurementId: "G-WNEXQ58KH7"

 
};
const app = initializeApp(config);
const db = getDatabase(app);

// const app = firebase.initializeApp(config);
// const db = firebase.database(app);



export default db ;
